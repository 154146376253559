<template>
  <div class="checkout-items pb-1 rounded">
    <div v-for="orga in organizationsMeta" :key="orga.id">
      <b-card class="h2 card-cart border-light"> {{ $t('trip.shop.request_summary_seller') }} {{ orga.legalName }} </b-card>

      <b-card v-for="product in cartLines" :key="product.id" no-body class="mb-2">
        <div v-if="orga.id === product.organization.id">
          <div class="d-flex border-light">
            <div class="flex-grow-1 p-1">
              <!-- Product Image -->
              <div class="d-inline-flex" style="width: 200px">
                <b-link
                  :to="{
                    name: getProductType(product.cartLineClass),
                    params: {
                      product_id: product.variant.productId,
                      provider_id: product.organization.id,
                      trip_id: $route.params.trip_id,
                      step_id: $route.params.step_id,
                      event_id: $route.params.event_id,
                      airport_id: $route.params.airport_id,
                    },
                    query: {
                      start_at: product.startAt,
                      end_at: product.endAt,
                    },
                  }"
                >
                  <b-img
                    :src="product.variant.files && product.variant.files.length ? product.variant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')"
                    :alt="`${product.variant.productName[userLang]}-${product.variant.id}`"
                    style="width: 200px"
                  />
                </b-link>
              </div>

              <!-- Product transfer Details: Card Body -->
              <div v-if="product.typology === 'helicopter'">
                <div class="item-name">
                  <h6 class="mb-0">
                    <b-link
                      class="text-body"
                      :to="{
                        name: 'shop-service-details',
                        params: {
                          product_id: product.variant.productId,
                          provider_id: product.organization.id,
                          trip_id: $route.params.trip_id,
                          step_id: $route.params.step_id,
                          event_id: $route.params.event_id,
                          airport_id: $route.params.airport_id,
                        },
                      }"
                    >
                      {{ product.variant.productName | trans }}
                    </b-link>
                  </h6>
                  <span class="item-company">
                    {{ $t('common.by') }} <b-link class="company-name">{{ product.brand }}</b-link>
                  </span>
                  <b-card-text v-if="product.typology !== 'service'">
                    <div class="col-12 px-0 py-50">
                      <font-awesome-icon icon="users" style="width: 25px" />
                      <span class="mx-50">
                        {{ product.passengers }} {{ $tc('trip.event.passenger_number', product.passengers) }}
                      </span>
                    </div>
                    <div class="col-12 px-0 py-50">
                      <font-awesome-icon icon="suitcase" style="width: 25px" />
                      <span class="mx-50">
                        {{ product.luggages }} {{ $tc('trip.event.luggage_number', product.luggages, product.luggages) }}
                      </span>
                    </div>
                    <div class="col-12 px-0 py-50">
                      <font-awesome-icon icon="tachometer-alt" style="width: 25px" />
                      <span class="mx-50">{{ product.speed }} km/h </span>
                    </div>
                  </b-card-text>
                </div>
              </div>

              <!-- Product Details: Card Body -->
              <div class="d-inline-flex ml-2">
                <div class="item-name">
                  <div class="d-inline-flex ml-2">
                    <h4 class="mb-0">
                      <b-link
                        :to="{
                          name: getProductType(product.cartLineClass),
                          params: {
                            product_id: product.variant.productId,
                            provider_id: product.organization.id,
                            trip_id: $route.params.trip_id,
                            step_id: $route.params.step_id,
                            event_id: $route.params.event_id,
                            airport_id: $route.params.airport_id,
                          },
                          query: {
                            start_at: product.startAt,
                            end_at: product.endAt,
                          },
                        }"
                        class="font-weight-bold"
                      >
                        {{ product.variant.productName | trans }}
                      </b-link>
                    </h4>
                    <div v-if="product.variant && product.variant.packing" class="ml-2">
                      <p class="pb-0 mb-0"> {{ $t('service.prices.type') }} : {{ product.variant.pricingType | enumTranslate('pricing_type') }}</p>
                      <p class="pb-0 mb-0"> {{ $t('service.prices.type_measurement') }} : {{ product.variant.packing.name | trans }}</p>
                    </div>
                  </div>

                  <div class="d-flex">
                    <!-- quantity -->
                    <div
                      v-if="!['mandatory_package_cart_line', 'hangar_cart_line'].includes(product.cartLineClass)
                        && !['mtow', 'wingspan', 'footprint'].includes(product.variant.packing.type)"
                      class="mt-1 mb-0 mr-1 font-weight-bold"
                      style="width: 200px"
                    >
                      <app-quantity-per-unit-input
                        :id="'quantity-' + product.variant.id"
                        v-model.number="product.quantity"
                        :unit.sync="product.quantityUnit"
                        :units-type="product.variant.packing.type"
                        :label="$t('common.quantity')"
                        :disabled="product.variant.packing && ['pax', 'crew'].includes(product.variant.packing.type)"
                        required
                        @change="patchCart(product)"
                      />
                    </div>

                    <!-- delivery date -->
                    <b-form-group
                      v-if="product.cartLineClass !== 'hangar_cart_line'"
                      class="mt-1 font-weight-bold"
                      :label="$t('shop.delivery_date')"
                      label-for="deliveryDate"
                    >
                      <flat-pickr
                        v-model="product.deliveryDate"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          altInput: true,
                          altFormat: 'F j, Y H:i',
                          dateFormat: 'Y-m-dTH:i',
                          localize: $i18n.locale,
                          disable: [date => $moment().diff(date) > 8640000],
                          time_24hr: true,
                        }"
                        @on-close="patchCartDelivery(product)"
                      />
                    </b-form-group>

                    <!-- hangar display -->
                    <p
                      v-if="product.cartLineClass === 'hangar_cart_line'"
                      class="mt-1"
                      :label="$t('shop.delivery_date')"
                      label-for="deliveryDate"
                    >
                      <ul class="list-unstyled">
                        <li v-if="product.startAt && product.endAt" class="mb-1">
                          <div
                            class="mt-1 mb-0 mr-1 d-flex align-items-end"
                          >
                            <app-input
                              :id="'quantity-' + product.variant.id"
                              :value="product.quantity"
                              :label="$t('common.quantity')"
                              disabled
                              style="width: 100px"
                            />
                            <span v-if="product.variantPrice" class="mb-1 ml-1">{{ $t('trip.shop.cart.unit_price') }} : {{ product.variantPrice.displayPriceExcludingTaxes | priceFormat }} {{ $t('common.excluding_taxes.abbreviation') }} </span>
                          </div>
                        </li>
                        <li v-if="product.startAt" class="mb-1">{{ $t('common.start_at') }} : {{ $moment.utc(product.startAt).format('ddd, MMM Do YYYY [at] HH:mm [UTC]') }} {{ $moment(product.startAt).format('[ (] HH:mm [LT )]') }}</li>
                        <li v-if="product.endAt" class="mb-1">{{ $t('common.end_at') }} : {{ $moment.utc(product.endAt).format('ddd, MMM Do YYYY [at] HH:mm [UTC]') }} {{ $moment(product.endAt).format('[ (] HH:mm [LT )]') }}</li>
                      </ul>
                    </p>
                  </div>

                  <!-- package_cart_line -->
                  <div
                    v-if="(product.cartLineClass === 'package_cart_line' || product.cartLineClass === 'mandatory_package_cart_line') && product.variants"
                    class="mt-1"
                  >
                    <ul v-for="variant in product.variants" :key="variant.id">
                      <li v-if="showVariant(variant) ">
                        <span class="text-nowrap">
                          {{
                            variant.name | trans
                          }} : {{
                            variant.minPrice.displayPriceExcludingTaxes * variant.quantity | priceFormat
                          }} {{ $tc('pricing.before_tax', 0) }} ({{
                            variant.vatRate | percentFormat
                          }} {{ $t('pricing.vat_rate') }})
                        </span>
                      </li>
                      <li v-else>
                        <span v-if="variant.name" class="text-nowrap">
                          {{ variant.name | trans }} : {{ $t('trip.shop.price_on_quote') }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-stretch justify-content-center align-items-center">
                <b-form-textarea
                  v-model="product.comment"
                  :placeholder="$t('trip.shop.add_comment')"
                  class="mt-1 mb-1 justify-content-left d-flex flex-wrap"
                  no-resize
                />
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <b-button
                  variant="outline-primary"
                  class="mt-32 mx-0 d-flex justify-content-center align-items-center"
                  @click="APISaveCommentCartRequest(product)"
                >
                  <font-awesome-icon icon="save" style="width: 1.25em" class="mr-50 text-primary" />
                  <span>{{ $t('action.save_comment') }}</span>
                </b-button>
              </div>
            </div>

            <!-- Product Options/Actions -->
            <!-- v-prices-width="setSameWidth" :style="`min-width: ${priceWidth}px`" -->
            <div v-prices-width="setSameWidth" :style="`min-width: ${priceWidth}px`">
              <div class="border-left-light p-1 d-flex align-items-center flex-column h-100 text-nowrap">
                <div class="m-0 list-view price-details-product">
                  <div v-if="product.variantPrice && product.variantPrice.sellingPriceIncludingTaxes">
                    <!-- TODO: refact v-prices-width -->
                    <ul class="text-right list-unstyled">
                      <li class="price-detail d-flex">
                        <div class="detail-titleA d-flex flex-grow-1 justify-content-end">{{ $t('trip.shop.cart.ex_tax_price') }} :</div>
                        <div v-if="product.cartLineClass === 'mandatory_package_cart_line'" v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product.variantPrice.sellingPriceExcludingTaxes | priceFormat }}
                        </div>
                        <div v-else v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product.variantPrice.displayPriceExcludingTaxes | priceFormat }}
                        </div>
                      </li>
                      <li v-if="product.cartLineClass === 'mandatory_package_cart_line'" class="price-detail d-flex">
                        <div class="detail-title d-flex flex-grow-1 justify-content-end">{{ $t('trip.shop.cart.discount') }} :</div>
                        <div v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product.variant.discount | percentFormat }}
                        </div>
                      </li>
                      <li class="price-detail d-flex">
                        <div class="detail-title d-flex flex-grow-1 justify-content-end">{{ $t('trip.shop.cart.total_ex_tax') }} :</div>
                        <div v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product | displayTotalPriceExcludingTaxes | priceFormat }}
                        </div>
                      </li>
                      <li class="price-detail d-flex">
                        <div class="detail-title d-flex flex-grow-1 justify-content-end">{{ $t('trip.shop.cart.eco_tax') }} :</div>
                        <div v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product.variantPrice.ecologicalTax | amountFormat(product.quantity) | priceFormat }}
                        </div>
                      </li>
                      <li class="price-detail d-flex">
                        <div class="detail-title d-flex flex-grow-1 justify-content-end">{{ $t('trip.shop.cart.vat') }} :</div>
                        <div v-if="product.cartLineClass === 'mandatory_package_cart_line'" v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product.variantPrice.vatAmount | priceFormat }}
                        </div>
                        <div v-else v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product.variant.vatRate | percentFormat }}
                        </div>
                      </li>
                      <li class="price-detail d-flex">
                        <div class="detail-title d-flex flex-grow-1 justify-content-end">{{ $t('trip.shop.cart.in_tax_price') }} :</div>
                        <div v-prices-width="setSameWidth2" :style="`min-width: ${priceWidth2}px`" class="d-flex flex-grow-1 justify-content-end">
                          {{ product | displayTotalPriceIncludingTaxes | priceFormat }}
                        </div>
                      </li>
                    </ul>
                  </div>

                  <p v-else class="d-flex justify-content-center align-items-center">
                    {{ $t('trip.shop.price_on_quote') }}
                  </p>
                </div>

                <div class="d-flex flex-column mt-auto">
                  <b-button
                    v-if="product.cartLineClass === 'mandatory_package_cart_line'"
                    block
                    class="mx-0"
                    :to="{ name: 'provider-details', params: $route.params }"
                  >
                    <feather-icon icon="RepeatIcon" class="mr-50" />
                    <span>{{ 'Change my package' }}</span>
                  </b-button>

                  <b-button variant="outline-danger" block class="mt-32 mx-0 remove-wishlist" @click="APIDeleteCartRequest(product.id)">
                    <feather-icon icon="Trash2Icon" class="mr-50" />
                    <span>{{ $t('action.delete') }}</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'

import spinInput from '@/components/SpinInput.vue'
import AppQuantityPerUnitInput from '@/components/AppQuantityPerUnitInput.vue'
import { deleteCartRequest, patchCartCommentRequest } from '@/request/globalApi/requests/tripStepEventRequests'
import Cart from '@/models/Cart'

export default defineComponent({
  name: 'CartProducts',

  components: {
    flatPickr,
    spinInput,
    AppQuantityPerUnitInput,
  },
  props: {
    cartLines: {
      type: Array,
      default: () => [],
    },
    organizationsMeta: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cart: new Cart(),
      priceWidth: null,
      priceWidth2: null,
    }
  },
  directives: {
    pricesWidth: {
      inserted(el, binding) {
        binding.value(el.offsetWidth)
      },
      update(el, binding) {
        binding.value(el.offsetWidth)
      },
    },
  },
  filters: {
    amountFormat(amount, quantity = 1) {
      return amount * quantity
    },
    displayTotalPriceIncludingTaxes(product) {
      const { quantity } = product
      const priceExcludingTaxes = product.variantPrice.displayPriceExcludingTaxes
      let priceIncludingTaxes = 0

      if (
        product.cartLineClass === 'mandatory_package_cart_line'
        && product.variant.discount
      ) {
        return product.variantPrice.displayPriceIncludingTaxes
      }

      priceIncludingTaxes = priceExcludingTaxes * (1 + (product.variant.vatRate / 100))

      return (priceIncludingTaxes * quantity) + (product.variantPrice.ecologicalTax * quantity)
    },
    displayTotalPriceExcludingTaxes(product) {
      const priceExcludingTaxes = product.variantPrice.displayPriceExcludingTaxes

      if (
        product.cartLineClass === 'mandatory_package_cart_line'
        && product.variant.discount
      ) {
        return product.variantPrice.displayPriceExcludingTaxes
      }

      return priceExcludingTaxes * product.quantity
    },
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  methods: {
    // TODO: refacto / rename its
    setSameWidth(elWidth) {
      if (elWidth > this.priceWidth) this.priceWidth = elWidth + 10
    },
    setSameWidth2(elWidth) {
      if (elWidth > this.priceWidth2) this.priceWidth2 = elWidth + 10
    },
    APIDeleteCartRequest(cartLineId) {
      const tripEventId = this.$route.params.event_id
      deleteCartRequest(tripEventId, cartLineId).then(isConfirmed => {
        if (isConfirmed) this.$emit('refetch-data')
      })
    },
    APISaveCommentCartRequest({ comment, variant, cartLineClass }) {
      const payload = {
        variantId: variant.id,
        cartLineClass,
        comment,
      }
      const tripEventId = this.$route.params.event_id
      patchCartCommentRequest(tripEventId, payload)
    },
    patchCart(product) {
      if (product.quantity !== null) {
        this.cart = new Cart()
        const tripStepId = this.$route.params.event_id
        this.cart.patch(tripStepId, product.variant.id, product.quantity).then(() => {
          this.$emit('refetch-data')
        })
      }
    },
    patchCartDelivery(product) {
      if (!product.deliveryDate) return
      const tripStepId = this.$route.params.event_id
      this.cart.patchDelivery(tripStepId, product)
    },
    showVariant(variant) {
      return !!(variant?.name && variant?.minPrice?.displayPriceExcludingTaxes && variant?.vatRate)
    },
    getProductType(cartLine) {
      if (cartLine === 'hangar_cart_line') {
        return 'hangar-detail'
      }
      if (cartLine === 'mandatory_package_cart_line') {
        return 'mandatory-package-detail'
      }
      return 'shop-service-details'
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
