<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->
    <cart-products
      :cart-lines.sync="cart.cartLines"
      :organizations-meta="organizationsMeta"
      @refetch-data="$emit('refetch-data')"
    />
    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>
        <!-- Trip details -->
        <cart-trip-details />
        <hr />
        <!-- Trip prices -->
        <div class="price-details">
          <h6 class="price-title">
            {{ $t('trip.shop.price_details') }}
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                {{ $tc('trip.shop.checkout.estimated_price', 1) }}
              </div>
            </li>

            <li class="price-detail">
              <div class="detail-title">{{ $t('trip.shop.cart.ex_tax_price') }} :</div>
              <div>{{ totalPriceExcludingTaxes | priceFormat }}</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">{{ $t('trip.shop.cart.eco_tax') }} :</div>
              <div>{{ ecologicalTaxPrice | priceFormat }}</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">{{ $t('trip.shop.cart.vat') }} :</div>
              <div>{{ vatPrice | priceFormat }}</div>
            </li>
            <li class="price-detail">
              <div class="detail-title">{{ $t('trip.shop.cart.in_tax_price') }} :</div>
              <div>{{ totalPriceIncludingTaxes | priceFormat }}</div>
            </li>
          </ul>
          <div v-if="nbPricesOnQuote" class="text-primary">
            {{ $t('trip.shop.cart.nb_services_on_quote', {nb: nbPricesOnQuote}) }}
          </div>
        </div>
      </b-card>

      <section>
        <validation-observer ref="checkoutServiceRules" tag="form">
          <template v-if="organizationsMeta.filter(org => org.cgvUrl).length">
            <validation-provider
              v-if="cart.cartLines.length"
              #default="{ errors }"
              :rules="{ required: { allowFalse: false } }"
              name="cgv"
            >
              <b-form-checkbox v-model="cgv" :state="errors.length > 0 ? false : null" value="cgv">
                {{ $tc('trip.shop.checkout.accept_cgv_all_cellers', organizationsMeta.length) }}
              </b-form-checkbox>
            </validation-provider>

            <ul>
              <li v-for="orga in organizationsMeta.filter(org => !!org.cgvUrl)" :key="orga.id">
                <a :href="orga.cgvUrl" target="_blank">{{ orga.legalName }}</a>
              </li>
            </ul>
          </template>

          <validation-provider
            v-show="cart.cartLines.length"
            #default="{ errors }"
            :rules="{ required: { allowFalse: false } }"
            name="commercial terms"
          >
            <b-form-checkbox
              v-model="commercialTerms"
              :state="errors.length > 0 ? false : null"
              value="commercialTerms"
            >
              <router-link :to="{ path: '/commercial-terms' }" class="text-underline" target="_blank">
                {{ $t('trip.shop.checkout.accept_commercial_conditions') }}
              </router-link>
            </b-form-checkbox>
          </validation-provider>

          <div class="mt-1 mb-2">
            <b-button v-show="cart.cartLines.length" block @click="$emit('next-step')">
              {{ $t('action.validate') }}
            </b-button>
            <b-button
              :to="{
                name: 'provider-details',
                params: {
                  provider_id: $route.params.provider_id,
                  trip_id: $route.params.trip_id,
                  step_id: $route.params.step_id,
                  event_id: $route.params.event_id,
                  airport_id: $route.params.airport_id,
                },
              }"
              block
              variant="outline-dark"
            >
              {{ $t('trip.shop.checkout.action.edit') }}
            </b-button>
          </div>
        </validation-observer>
      </section>
    </div>
  </div>
</template>
<script>
import CartTripDetails from '@/views/cart/CartTripDetails.vue'
import CartProducts from '@/views/cart/CartProducts.vue'

export default {
  name: 'CartLines',

  components: {
    CartProducts,
    CartTripDetails,
  },
  props: {
    cart: {
      type: Object,
      default: () => ({}),
    },
    organizationsMeta: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cgv: false,
      commercialTerms: false,
      nbPricesOnQuote: 0,
    }
  },
  computed: {
    totalPriceExcludingTaxes() {
      let price = 0
      this.cart.cartLines.forEach(el => {
        if (el.variantPrice && !Number.isNaN(el.variantPrice.displayPriceExcludingTaxes)) {
          price += el.variantPrice.displayPriceExcludingTaxes * el.quantity
        }
      })
      return price
    },
    totalPriceIncludingTaxes() {
      let price = 0

      this.cart.cartLines.forEach(el => {
        if (el.variantPrice && !Number.isNaN(el.variantPrice.displayPriceExcludingTaxes)) {
          let totalPriceExcludingTaxes = 0
          let totalPriceEcologicalTax = 0

          if (el.cartLineClass === 'mandatory_package_cart_line') {
            price += el.variantPrice.displayPriceIncludingTaxes
          } else {
            totalPriceExcludingTaxes = el.variantPrice.displayPriceExcludingTaxes * el.quantity
            totalPriceEcologicalTax = el.variantPrice.ecologicalTax * el.quantity

            price += (totalPriceExcludingTaxes * (1 + (el.variant.vatRate / 100))) + totalPriceEcologicalTax
          }
        }
      })

      return price
    },
    vatPrice() {
      let price = 0
      this.cart.cartLines.forEach(el => {
        if (el.variantPrice && !Number.isNaN(el.variantPrice.displayPriceExcludingTaxes)) {
          if (el.cartLineClass === 'mandatory_package_cart_line') {
            price += el.variantPrice.vatAmount
          } else {
            price += (el.variantPrice.displayPriceExcludingTaxes * el.quantity) * (el.variant.vatRate / 100)
          }
        }
      })

      return price
    },
    ecologicalTaxPrice() {
      let price = 0
      this.cart.cartLines.forEach(el => {
        if (el.variantPrice && !Number.isNaN(el.variantPrice.displayPriceExcludingTaxes)) {
          price += el.variantPrice.ecologicalTax * el.quantity
        }
      })

      return price
    },
  },
  watch: {
    'cart.cartLines': {
      handler() {
        this.getNbPricesOnQuote()
      },
    },
  },
  methods: {
    getNbPricesOnQuote() {
      let nb = 0
      this.cart.cartLines.forEach(cl => {
        if (!cl.variantPrice) nb += cl.quantity
      })
      this.nbPricesOnQuote = nb
    },
    sum(price) {
      let sum = 0
      this.cart.cartLines.forEach(el => {
        if (el.variantPrice && !Number.isNaN(el.variantPrice[price])) {
          sum += el.variantPrice[price] * el.quantity
        }
      })
      return sum
    },
  },
}
</script>
